import React, { useState, useEffect } from 'react';
import upGreenRight from '../../srcImages/UpGreenRight.png'
import downRedRight from '../../srcImages/DownRedRight.png'
import BasicTickerEvaluaton from '../../Components/BasicTickerEvaluaton/BasicTickerEvaluaton'

const CURRENT_HOLDINGS = 
  [
    {
      ticker: "DIA",
      costBasis: '408.50',
      unitsOnHand: 3,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "VOO",
      costBasis: '435.70',
      unitsOnHand: 44, 
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "QQQ",
      costBasis: '463.30',
      unitsOnHand: 25,  
      calculateAccumulatedProfitLoss: true,               
    },    
    {
      ticker: "AAPL",
      costBasis: '188.47',
      unitsOnHand: 69, 
      calculateAccumulatedProfitLoss: true,         
    },    
    {
      ticker: "ABT",
      costBasis: '113.10',
      unitsOnHand: 40, 
      calculateAccumulatedProfitLoss: true,         
    },    
    {
      ticker: "AMD",
      costBasis: '155.51',
      unitsOnHand: 135, 
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "AMZN",
      costBasis: '141.88', 
      unitsOnHand: 147, 
      calculateAccumulatedProfitLoss: true,        
    },
    {
      ticker: "AVGO",
      costBasis: '124.97', 
      unitsOnHand: 148,  
      calculateAccumulatedProfitLoss: true,       
    },
    {
      ticker: "BBY",
      costBasis: '100.43',
      unitsOnHand: 25,
      calculateAccumulatedProfitLoss: true,          
    },
    {
      ticker: "BRK-B",
      costBasis: '360.92',
      unitsOnHand: 71,
      calculateAccumulatedProfitLoss: true,          
    },
    {
      ticker: "COF",
      costBasis: '109.37',
      unitsOnHand: 438,
      calculateAccumulatedProfitLoss: false,
    },
    {
      ticker: "COST",
      costBasis: '904.00',
      unitsOnHand: 6,
      calculateAccumulatedProfitLoss: true,          
    },
    {
      ticker: "CRWD",
      costBasis: '329.42',
      unitsOnHand: 6,
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "DD",
      costBasis: '83.14',
      unitsOnHand: 15,
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "DOV",
      costBasis: '183.67',
      unitsOnHand: 18,
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "ENB",
      costBasis: '35.56', 
      unitsOnHand: 70,
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "ETN",
      costBasis: '283.6', 
      unitsOnHand: 20,
      calculateAccumulatedProfitLoss: true,         
    },
    {
      ticker: "HON",
      costBasis: '205.10', 
      unitsOnHand: 12,
      calculateAccumulatedProfitLoss: true,     
    },
    {
      ticker: "HD",
      costBasis: '364.95', 
      unitsOnHand: 8,
      calculateAccumulatedProfitLoss: true,     
    },
    {
      ticker: "JEPI",
      costBasis: '54.99', 
      unitsOnHand: 30,
      calculateAccumulatedProfitLoss: true,    
    },
    {
      ticker: "LOW",
      costBasis: '244.26', 
      unitsOnHand: 11,
      calculateAccumulatedProfitLoss: true,     
    },
    {
      ticker: "LULU",
      costBasis: '276.99', 
      unitsOnHand: 10,
      calculateAccumulatedProfitLoss: true,     
    },
    {
      ticker: "META",
      costBasis: '335.77', 
      unitsOnHand: 58,
      calculateAccumulatedProfitLoss: true,    
    },
    {
      ticker: "MSFT",
      costBasis: '340.75',
      unitsOnHand: 82,
      calculateAccumulatedProfitLoss: true,      
    },
    {
      ticker: "NLY",
      costBasis: '19.986',
      unitsOnHand: 300,
      calculateAccumulatedProfitLoss: true,      
    },
    {
      ticker: "NVDA",
      costBasis: '59.29',
      unitsOnHand: 495,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "O",
      costBasis: '56.12',
      unitsOnHand: 120,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "PANW",
      costBasis: '182.34',
      unitsOnHand: 20,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "PDI",
      costBasis: '19.33',
      unitsOnHand: 80,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "SWK",
      costBasis: '108.45',
      unitsOnHand: 15,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "ULTA",
      costBasis: '387.13',
      unitsOnHand: 6,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "WFC",
      costBasis: '51.88',
      unitsOnHand: 131,
      calculateAccumulatedProfitLoss: true,      
    },
    {
      ticker: "WMT",
      costBasis: '79.84',
      unitsOnHand: 30,
      calculateAccumulatedProfitLoss: true,      
    }

  ];


function CurrentHoldings() {

  const [stockSymbolToFetch,setStockSymbolToFetch] = useState('AAPL')
  const [headerValue,setHeaderValue] = useState('Current ')
  const [todaysPercentageChange, setTodaysPercentageChange] = useState(0.0);
  const [isTodaysChangePositive, setIsTodaysChangePositive] = useState(true);
  const [slope, setSlope] = useState(0.0)
  const [currentHoldings,setCurrentHoldings]=useState(CURRENT_HOLDINGS);

  const onSelectTickerButtonHandler=(tickerToEvaluate)=>
  {
    setStockSymbolToFetch(tickerToEvaluate)
    //console.log("Setting stockSymbolToFetch: " +stockSymbolToFetch)
  }

  const onSetHeader=(headerValueIn)=>
  {
    setHeaderValue(headerValueIn)
  }

  const onSetTodaysPercentageChange = (percentageChange, isChnagePositive) => {
    setTodaysPercentageChange(percentageChange);
    setIsTodaysChangePositive(isChnagePositive);
  }

  const onSetSlope = (slopeIn) => {
    setSlope(slopeIn)
  }

  useEffect(() => {
    document.title = "Current Holdings"
 }, []);


  useEffect(() => {  
    //console.log("Running useEffect for: stockSymbolToFetch: " +stockSymbolToFetch)
}, [stockSymbolToFetch,headerValue,slope]);

  return (
    <div className="text-center">      
      
    <header className="bg-indigo-100 text-purple-600 text-3xl font-bold h-18 justify-items-center">
      <div>
        {headerValue}
      </div>   
      <div>
                {isTodaysChangePositive === true ?
                    <div className='text-green-600 text-3xl font-bold'>
                        Today's Change: {todaysPercentageChange} %
                    </div> :
                    <div className='text-red-600 text-3xl font-bold'>
                        Today's Change: {todaysPercentageChange} %
                    </div>
                    }
      </div>     
      <div>
        {slope >= 0.0 ?
          <div className='text-green-600 text-3xl font-bold'>            
                {/*Exponential change: {slope}  */}              
                <img className="inline-block w-10 h-8 ml-7 " src={upGreenRight} alt=""></img>                           
          </div> :
          <div className='text-red-600 text-3xl font-bold'>
                {/*} Exponential change: {slope} */} 
                  <img className="inline-block w-12 h-10 ml-7" src={downRedRight} alt=""></img> 
          </div>
          }
      </div>          
    </header>

    <BasicTickerEvaluaton onSelectTickerButtonHandler = {onSelectTickerButtonHandler} onSetHeader = {onSetHeader} baseHeader='Current'
     onSetTodaysPercentageChange={onSetTodaysPercentageChange}
                          onSetSlope = {onSetSlope} tickerEntries={currentHoldings} backgroundLeft='bg-indigo-100'
                          buttonBackgroundColor='bg-indigo-400'/>
    {/*<StockQuote stockSymbol={stockSymbolToFetch}/>*/}
    </div>
  );
}

export default CurrentHoldings;
